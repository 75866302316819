import HeaderOnlineOrder from "@/components/front/HeaderOnlineOrder";
import Footer from "@/components/front/FooterOnlineOrder";
import Spinner from "@/components/front/Loader/Spinner";
import GrayOutScreen from "@/components/ui/dropdowns/GrayOutScreen";
import { setBranchId, setServiceFee, setTaxRate } from "@/features/cart/cart";
import BranchMap from "@/components/front/BranchMap";
import { RootState } from "@/store";
import "@/assets/css/OnlineOrder.css";
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import question from "@/assets/img/question.png";
import clock from "@/assets/img/clock.png";
import DesktopOperationalTimes from "./StoreFront/Component/DesktopOperationalTimes";
import MobileOperationalTimes from "./StoreFront/Component/MobilOperationalTimes";

const ProductList = lazy(() => import("@/components/front/ProductList"));
const CategoryNavigation = lazy(
  () => import("@/components/front/CategoryNavigation")
);

const API_URL = process.env.REACT_APP_API_BASE_URL;

interface Address {
  id: number;
  street: string;
  city: string;
  province: string;
  province_code: string;
  postal_code: string;
  country: string;
  country_code: string;
  latitude: number;
  longitude: number;
}

interface Branch {
  id: number;
  name: string;
  operating_hours: OperatingHour[];
  service_fee: string;
  tax_rate: string;
  logo: string;
  promotion_text: string;
  address: Address;
  phone: any;
  website: any;
}

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

interface Category {
  id: number;
  name: string;
  category_hours: OperatingHour[];
  hasSpecialHours: boolean;
  ranking: number;
}

interface OnlineOrderProps {
  subdomain: string;
  hostname: string;
  branches: {
    count: number;
    results: Branch[];
  };
  isInitialLoad: boolean;
}

const MemoizedCategoryNavigation = React.memo(CategoryNavigation);

const OnlineOrder: React.FC<OnlineOrderProps> = ({
  branches,
  isInitialLoad,
}) => {
  const dispatch = useDispatch();
  const selectedBranch = useSelector(
    (state: RootState) => state.foodCart.branchId
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(isInitialLoad);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [isSelectedBranchOpen, setIsSelectedBranchOpen] =
    useState<boolean>(true);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const fetchedBranchesRef = useRef<Set<string>>(new Set());
  const isFetchingRef = useRef(false);
  const [isCategoryFixed, setIsCategoryFixed] = useState(false);
  const promoTextRef = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);

  const setPromoTextRef = useCallback((node: HTMLDivElement | null) => {
    if (promoTextRef.current && observer.current) {
      // Unobserve the previous element
      observer.current.unobserve(promoTextRef.current);
    }

    if (node && observer.current) {
      // Observe the new element
      observer.current.observe(node);
    }

    // Update the ref
    promoTextRef.current = node;
  }, []);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([entry]) => {
        console.log("Intersecting Observer", !entry.isIntersecting);
        setIsCategoryFixed(!entry.isIntersecting);
      },
      { threshold: 0 }
    );

    const currentPromoRef = promoTextRef.current;
    if (currentPromoRef) {
      observer.current.observe(currentPromoRef);
    }

    return () => {
      if (currentPromoRef && observer.current) {
        observer.current.unobserve(currentPromoRef);
      }
      observer.current?.disconnect();
    };
  }, []);

  const checkIfBranchIsOpen = (operatingHours: OperatingHour[]): boolean => {
    const now = new Date();
    const dayOfWeek = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = now.toTimeString().split(" ")[0];

    const [currentHour, currentMinute, currentSecond] = currentTime
      .split(":")
      .map(Number);
    const currentTimeInSeconds =
      currentHour * 3600 + currentMinute * 60 + currentSecond;

    return operatingHours.some((hour) => {
      if (hour.day !== dayOfWeek) return false;

      const [openHour, openMinute] = hour.open_time.split(":").map(Number);
      const [closeHour, closeMinute] = hour.close_time.split(":").map(Number);

      const openTimeInSeconds = openHour * 3600 + openMinute * 60;
      const closeTimeInSeconds = closeHour * 3600 + closeMinute * 60;

      return (
        currentTimeInSeconds >= openTimeInSeconds &&
        currentTimeInSeconds <= closeTimeInSeconds
      );
    });
  };

  const checkIfCategoryIsOpen = (categoryHours: OperatingHour[]): boolean => {
    const now = new Date();
    const dayOfWeek = now.toLocaleString("en-US", { weekday: "long" });
    const currentTime = now.toTimeString().split(" ")[0];

    const [currentHour, currentMinute, currentSecond] = currentTime
      .split(":")
      .map(Number);
    const currentTimeInSeconds =
      currentHour * 3600 + currentMinute * 60 + currentSecond;

    return categoryHours.some((hour) => {
      if (hour.day !== dayOfWeek) return false;

      const [openHour, openMinute] = hour.open_time.split(":").map(Number);
      const [closeHour, closeMinute] = hour.close_time.split(":").map(Number);
      const openTimeInSeconds = openHour * 3600 + openMinute * 60;
      const closeTimeInSeconds = closeHour * 3600 + closeMinute * 60;

      return (
        currentTimeInSeconds >= openTimeInSeconds &&
        currentTimeInSeconds <= closeTimeInSeconds
      );
    });
  };

  const filterCategoriesByTime = useCallback(
    (categories: Category[]): Category[] => {
      return categories.filter(
        (category) =>
          !category.hasSpecialHours ||
          checkIfCategoryIsOpen(category.category_hours)
      );
    },
    []
  );

  const fetchCategories = useCallback(
    async (branchId: string) => {
      if (fetchedBranchesRef.current.has(branchId) || isFetchingRef.current) {
        return;
      }

      isFetchingRef.current = true;
      setLoading(true);
      try {
        console.log(`Fetching categories for branch: ${branchId}`);
        const response = await fetch(
          `${API_URL}api/v1/category-front/?branch=${branchId}&status=Active`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();

        const updatedCategories = result.map((category: Category) => ({
          ...category,
          hasSpecialHours: category.name
            .toLowerCase()
            .includes("lunch special"),
        }));

        setAllCategories(updatedCategories);

        const filteredCategories = filterCategoriesByTime(updatedCategories);

        // Sort categories by ranking
        const sortedCategories = filteredCategories.sort(
          (a: Category, b: Category) => a.ranking - b.ranking
        );

        setCategories(sortedCategories);

        // Set the initial selected category to the one with ranking 0
        if (sortedCategories.length > 0 && !selectedCategory) {
          setSelectedCategory(sortedCategories[0].name);
        }

        setInitialLoadComplete(true);
        fetchedBranchesRef.current.add(branchId);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
        isFetchingRef.current = false;
      }
    },
    [filterCategoriesByTime, selectedCategory]
  );

  useEffect(() => {
    const setInitialBranch = async () => {
      if (branches.count === 1) {
        const singleBranch = branches.results[0];
        dispatch(setBranchId(singleBranch.id.toString()));
        setOperatingHours(singleBranch.operating_hours);
        setIsSelectedBranchOpen(
          checkIfBranchIsOpen(singleBranch.operating_hours)
        );
        dispatch(setServiceFee(parseFloat(singleBranch.service_fee)));
        dispatch(setTaxRate(parseFloat(singleBranch.tax_rate)));
        await fetchCategories(singleBranch.id.toString());
      } else if (selectedBranch) {
        const branch = branches.results.find(
          (b) => b.id.toString() === selectedBranch
        );
        if (branch) {
          setOperatingHours(branch.operating_hours);
          setIsSelectedBranchOpen(checkIfBranchIsOpen(branch.operating_hours));
          if (!fetchedBranchesRef.current.has(selectedBranch)) {
            await fetchCategories(selectedBranch);
          }
        }
      }
      setLoading(false);
    };
    setInitialBranch();
  }, [branches, selectedBranch, dispatch, fetchCategories]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (selectedBranch) {
        const branch = branches.results.find(
          (b) => b.id.toString() === selectedBranch
        );
        if (branch) {
          setIsSelectedBranchOpen(checkIfBranchIsOpen(branch.operating_hours));
        }
      }
      setCategories(filterCategoriesByTime(allCategories));
    }, 60000);
    return () => clearInterval(intervalId);
  }, [branches, selectedBranch, allCategories, filterCategoriesByTime]);

  useEffect(() => {
    if (
      !isInitialLoad &&
      selectedBranch &&
      isSelectedBranchOpen &&
      !initialLoadComplete
    ) {
      fetchCategories(selectedBranch);
    }
  }, [
    selectedBranch,
    isSelectedBranchOpen,
    isInitialLoad,
    initialLoadComplete,
    fetchCategories,
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [loading]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBranchId = event.target.value;
    dispatch(setBranchId(selectedBranchId));
    setSelectedCategory(null);
    setCategories([]);
    const selectedBranch = branches.results.find(
      (branch) => branch.id === parseInt(selectedBranchId)
    );
    if (selectedBranch) {
      setOperatingHours(selectedBranch.operating_hours);
      setIsSelectedBranchOpen(
        checkIfBranchIsOpen(selectedBranch.operating_hours)
      );
      dispatch(setServiceFee(parseFloat(selectedBranch.service_fee)));
      dispatch(setTaxRate(parseFloat(selectedBranch.tax_rate)));
    }
    setInitialLoadComplete(false);
    fetchCategories(selectedBranchId);
  };

  const handleCategorySelect = useCallback((category: string) => {
    const headerHeight = document.querySelector("header")?.clientHeight || 50;
    const searchBarPosition =
      searchBarRef.current?.getBoundingClientRect().top || 0;
    const offsetPosition =
      window.pageYOffset + searchBarPosition - headerHeight - 10;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    setSelectedCategory(category); // Set the selected category
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const selectedBranchData = branches.results.find(
    (branch) => branch.id.toString() === selectedBranch
  );

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col relative pt-20">
      <div>
        <HeaderOnlineOrder isSelectedBranchOpen={isSelectedBranchOpen} />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          {branches.count > 1 && (
            <div className="flex justify-center">
              <select
                value={selectedBranch || ""}
                onChange={handleSelectChange}
                className="py-2 pr-8 border rounded"
              >
                <option value="" disabled>
                  Select a Branch
                </option>
                {branches.results.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {!loading && selectedBranch && (
            <div>
              {!isSelectedBranchOpen && (
                <GrayOutScreen message="Thank you for choosing us! Unfortunately, we are currently closed. Please come back during our opening hours." />
              )}
              {categories.length > 0 && (
                <>
                  <div className="max-w-7xl mx-auto p-4">
                    <div className="flex flex-col items-center mb-6 md:flex-row md:justify-between md:items-start">
                      <div
                        className={`w-full md:w-2/3 pr-4 md:pr-0 ${
                          branches.count > 1 ? "mt-0" : "mt-16"
                        } md:mt-0`}
                      >
                        <img
                          src={selectedBranchData?.logo}
                          alt="Business"
                          className="w-full h-64 md:h-72 object-cover rounded-lg border border-gray-300"
                        />
                      </div>
                      <div className="operatedesktop">
                        <DesktopOperationalTimes
                          operatingHours={operatingHours}
                        />
                      </div>
                    </div>
                    <div className="modalmobile">
                      <div className="branch-info-modal">
                        <h2>{selectedBranchData?.name}</h2>
                        <div className="address">
                          {selectedBranchData?.address.street},{" "}
                          {selectedBranchData?.address.city},{" "}
                          {selectedBranchData?.address.province_code}{" "}
                          {selectedBranchData?.address.postal_code}
                        </div>
                        <div onClick={handleModalOpen}>
                          <button className="tap-for-info mt-2 bg-red-500 text-white font-semibold py-2 px-2 rounded-2xl cursor-pointer">
                            Tap for More Info
                          </button>
                        </div>
                      </div>
                      {isModalOpen && (
                        <div
                          className="modal-overlay"
                          onClick={handleModalClose}
                        >
                          <div
                            className="modal-content"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button
                              className="close-button"
                              onClick={handleModalClose}
                            >
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                            <div className="contact-info">
                              <h2 className="contact-title">
                                <img
                                  src={question}
                                  alt="Contact Icon"
                                  className="icon"
                                />
                                Contact Information
                              </h2>
                              <p>
                                If you have allergies or other dietary
                                restrictions, please contact the restaurant. The
                                restaurant will provide food-specific
                                information upon request.
                              </p>
                              <p className="phone-number">
                                Phone number: {selectedBranchData?.phone}
                              </p>
                            </div>
                            <div className="operational-times">
                              <h2 className="operational-title">
                                <img
                                  src={clock}
                                  alt="Contact Icon"
                                  className="opeartionalicon"
                                />
                                Operational Times
                              </h2>
                              <MobileOperationalTimes
                                operatingHours={
                                  selectedBranchData?.operating_hours || []
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {selectedBranchData?.promotion_text && (
                      <div
                        className="bg-gray-100 p-4 rounded-lg mb-6"
                        ref={setPromoTextRef}
                      >
                        <p>{selectedBranchData.promotion_text}</p>
                      </div>
                    )}
                    <div className="flex justify-between items-center">
                      <h2 className="text-2xl font-bold hidden md:block">
                        All Offers from {selectedBranchData?.name}
                      </h2>
                      <div
                        className="relative w-full md:w-64"
                        ref={
                          !selectedBranchData?.promotion_text
                            ? setPromoTextRef
                            : null
                        }
                      >
                        <div
                          className="flex justify-between items-center"
                          ref={searchBarRef}
                        >
                          <input
                            type="text"
                            placeholder="Search from menu..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="p-2 border rounded-full w-full md:w-64 pl-10"
                          />
                        </div>
                        <svg
                          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width="20"
                          height="20"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-4.35-4.35M16.65 16.65A7.5 7.5 0 1116.65 2a7.5 7.5 0 010 15z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <Suspense fallback={<Spinner />}>
                    <MemoizedCategoryNavigation
                      categories={categories.map((category) => ({
                        id: category.id,
                        name: category.name,
                        ranking: category.ranking,
                      }))}
                      selectedCategory={selectedCategory}
                      onCategorySelect={handleCategorySelect}
                      isCategoryFixed={isCategoryFixed}
                    />
                  </Suspense>
                  <Suspense fallback={<Spinner />}>
                    <ProductList
                      branchId={selectedBranch}
                      category={selectedCategory || categories[0].name}
                      search={searchTerm}
                    />
                  </Suspense>
                </>
              )}
            </div>
          )}
          <Suspense fallback={<Spinner />}>
            <BranchMap
              address={
                selectedBranchData?.address ?? {
                  id: 0,
                  street: "",
                  city: "",
                  latitude: 0,
                  longitude: 0,
                  postal_code: "",
                  province: "",
                  province_code: "",
                  country: "",
                  country_code: "",
                }
              }
              phoneNumber={selectedBranchData?.phone}
              website={selectedBranchData?.website}
              branchName={selectedBranchData?.name ?? ""}
            />
          </Suspense>
        </div>
      )}
      <Suspense fallback={<Spinner />}>
        <Footer />
      </Suspense>
    </div>
  );
};

export default OnlineOrder;
