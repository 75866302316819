import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrash,
  faEdit,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import { useAxiosInstance } from "@/utils/useAxiosInstance";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
  branch: number;
}

interface BranchOperatingHoursInputProps {
  operatingHours: OperatingHour[];
  setOperatingHours: React.Dispatch<React.SetStateAction<OperatingHour[]>>;
  branchId: number;
}

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const getDayIndex = (day: string): number => {
  return daysOfWeek.indexOf(day);
};

const sortOperatingHours = (hours: OperatingHour[]): OperatingHour[] => {
  return [...hours].sort((a, b) => {
    const dayDiff = getDayIndex(a.day) - getDayIndex(b.day);
    if (dayDiff !== 0) return dayDiff;

    return a.open_time.localeCompare(b.open_time);
  });
};

const BranchOperatingHoursInput: React.FC<BranchOperatingHoursInputProps> = ({
  operatingHours,
  setOperatingHours,
  branchId,
}) => {
  const [axiosInstance] = useAxiosInstance();
  const [editingId, setEditingId] = useState<number | null>(null);
  const [localHours, setLocalHours] = useState<OperatingHour[]>(operatingHours);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteHourId, setDeleteHourId] = useState<number | null>(null);

  const sortedLocalHours = useMemo(
    () => sortOperatingHours(localHours),
    [localHours]
  );

  useEffect(() => {
    setLocalHours(operatingHours);
  }, [operatingHours]);

  const handleAddOperatingHour = () => {
    const newHour: OperatingHour = {
      id: -Date.now(),
      day: "Wednesday",
      open_time: "11:00",
      close_time: "21:00",
      branch: branchId,
    };
    setLocalHours((prev) => [...prev, newHour]);
    setEditingId(newHour.id);
    setIsDirty(true);
  };

  const handleEditOperatingHour = (id: number) => {
    setEditingId(id);
  };

  const handleSaveOperatingHour = async (id: number) => {
    try {
      const hourToSave = localHours.find((hour) => hour.id === id);
      if (!hourToSave) return;

      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/operating-hours/`;
      let savedHour: OperatingHour;

      if (id < 0) {
        // New hour
        const { id: _, ...hourData } = hourToSave;
        const response = await axiosInstance.post(apiUrl, hourData);
        savedHour = response.data;
      } else {
        // Existing hour
        const response = await axiosInstance.patch(
          `${apiUrl}${id}/`,
          hourToSave
        );
        savedHour = response.data;
      }

      setLocalHours((prev) =>
        prev.map((hour) => (hour.id === id ? savedHour : hour))
      );
      setOperatingHours((prev) =>
        prev.map((hour) => (hour.id === id ? savedHour : hour))
      );
      setEditingId(null); // Reset editing mode
      setIsDirty(false);
    } catch (error) {
      console.error("Error saving operating hour:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingId(null);
    setLocalHours(operatingHours);
    setIsDirty(false);
  };

  const handleInputChange = (id: number, field: string, value: string) => {
    setLocalHours((prev) =>
      prev.map((hour) => (hour.id === id ? { ...hour, [field]: value } : hour))
    );
    setIsDirty(true);
  };

  const handleDeleteOperatingHour = (id: number) => {
    setDeleteHourId(id);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteOperatingHour = async () => {
    if (deleteHourId === null) return;

    try {
      if (deleteHourId >= 0) {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/operating-hours/${deleteHourId}/`;
        await axiosInstance.delete(apiUrl);
      }

      // Update both local and parent state
      const updatedHours = localHours.filter(
        (hour) => hour.id !== deleteHourId
      );
      setLocalHours(updatedHours);
      setIsDirty(true);

      setShowDeleteConfirmation(false);
      setDeleteHourId(null);
    } catch (error) {
      console.error("Error deleting operating hour:", error);
    }
  };

  const saveAllChanges = async () => {
    setIsSaving(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/operating-hours/`;

      const updatedHours: OperatingHour[] = [];

      for (const hour of localHours) {
        if (hour.id < 0) {
          // New hour, create with POST
          const { id, ...hourData } = hour;
          const response = await axiosInstance.post(apiUrl, hourData);
          updatedHours.push(response.data);
        } else {
          // Existing hour, update with PATCH
          const response = await axiosInstance.patch(
            `${apiUrl}${hour.id}/`,
            hour
          );
          updatedHours.push(response.data);
        }
      }

      setOperatingHours(updatedHours);
      setLocalHours(updatedHours);
      setIsDirty(false);
      setEditingId(null);
    } catch (error) {
      console.error("Error saving operating hours:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const discardChanges = () => {
    setLocalHours(operatingHours);
    setIsDirty(false);
    setEditingId(null);
  };

  const renderOperatingHour = (hour: OperatingHour) => {
    const isEditing = editingId === hour.id;

    return (
      <div key={hour.id} className="mb-4 bg-gray-50 rounded-lg overflow-hidden">
        <div className="p-4 flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="flex-grow md:grid md:grid-cols-3 md:gap-4 text-2xl md:text-2xl mb-4 md:mb-0">
            <div className="font-medium mb-2 md:mb-0">{hour.day}</div>
            <div className="flex justify-between md:col-span-2">
              <span>{hour.open_time}</span>
              <span className="mx-2"> - </span>
              <span>{hour.close_time}</span>
            </div>
          </div>
          <div className="flex items-center justify-center space-x-4 md:space-x-2 md:justify-end">
            {isEditing ? (
              <>
                <button
                  onClick={() => handleSaveOperatingHour(hour.id)}
                  className="flex-1 md:flex-none p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                  <FontAwesomeIcon icon={faSave} />
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="flex-1 md:flex-none p-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => handleEditOperatingHour(hour.id)}
                  className="flex-1 md:flex-none p-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  onClick={() => handleDeleteOperatingHour(hour.id)}
                  className="flex-1 md:flex-none p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderEditingHour = (hour: OperatingHour) => {
    return (
      <div key={hour.id} className="mb-4 bg-gray-50 rounded-lg overflow-hidden">
        <div className="p-4 flex flex-col md:flex-row md:items-center md:space-x-4">
          <div className="flex-grow grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 md:mb-0">
            <div>
              <select
                value={hour.day}
                onChange={(e) =>
                  handleInputChange(hour.id, "day", e.target.value)
                }
                className="w-full p-2 border rounded text-"
              >
                {daysOfWeek.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex justify-between space-x-2 md:col-span-2">
              <input
                type="time"
                value={hour.open_time}
                onChange={(e) =>
                  handleInputChange(hour.id, "open_time", e.target.value)
                }
                className="flex-1 p-2 border rounded text-md"
              />
              <span className="flex items-center mx-2"> - </span>
              <input
                type="time"
                value={hour.close_time}
                onChange={(e) =>
                  handleInputChange(hour.id, "close_time", e.target.value)
                }
                className="flex-1 p-2 border rounded text-md"
              />
            </div>
          </div>
          <div className="flex items-center justify-center space-x-4 md:space-x-2 md:justify-end">
            <button
              onClick={() => handleSaveOperatingHour(hour.id)}
              className="flex-1 md:flex-none p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            <button
              onClick={handleCancelEdit}
              className="flex-1 md:flex-none p-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition-colors"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      {isDirty && (
        <ContextualSaveBar
          isDirty={isDirty}
          onSave={saveAllChanges}
          onDiscard={discardChanges}
          isSaving={isSaving}
        />
      )}
      <div className="p-4 md:p-6 md:max-w-3xl md:mx-auto">
        <h3 className="font-bold text-xl mb-4 md:mb-6">Operating Hours</h3>
        <div className="space-y-2 md:space-y-4">
          {sortedLocalHours.map((hour) =>
            editingId === hour.id
              ? renderEditingHour(hour)
              : renderOperatingHour(hour)
          )}
        </div>
        <button
          onClick={handleAddOperatingHour}
          className="mt-4 md:mt-6 p-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors w-full md:w-auto"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Add Operating Hour
        </button>
      </div>

      {showDeleteConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p className="mb-6">
              Are you sure you want to delete this operating hour?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={confirmDeleteOperatingHour}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BranchOperatingHoursInput;
