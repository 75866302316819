import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "@/features/cart/cart";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import "@/assets/css/ProductModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

interface Option {
  id: number;
  name: string;
  description: string;
  surcharge: string;
  created_at: string;
}

interface OptionList {
  id: number;
  name: string;
  options: Option[];
  required: boolean;
  option_type: string;
  max_selections: number | null;
}

interface Product {
  id: number;
  name: string;
  unit_price: string;
  description: string;
  image: string;
  branch: number;
  product_option_lists: OptionList[];
  is_upsell: boolean;
}

interface SelectedOption {
  quantity: number;
  selected: boolean;
  surcharge?: number;
}

interface CartItem extends Product {
  quantity: number;
  selected_options: { [key: string]: SelectedOption };
  uniqueId: string;
}

interface ProductModalProps {
  product: Product;
  onClose: () => void;
}

interface SelectedOptions {
  [key: string]: SelectedOption;
}

const ProductModal: React.FC<ProductModalProps> = ({ product, onClose }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [axiosInstance, loading] = useAxiosInstance();
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
  const [optionLists, setOptionLists] = useState<OptionList[]>(
    product.product_option_lists
  );

  const fetchOptionLists = async () => {
    try {
      let allFetchedOptionLists: OptionList[] = [];
      allFetchedOptionLists = [...optionLists];
      // Sorting logic
      const sortedOptionLists = allFetchedOptionLists.sort((a, b) => {
        // Sort by option_type
        const typeOrder: { [key: string]: number } = {
          multiple_choice: 1,
          checkbox: 2,
          increment_decrement: 3,
        };
        if (typeOrder[a.option_type] !== typeOrder[b.option_type]) {
          return typeOrder[a.option_type] - typeOrder[b.option_type];
        }

        // If option_type is the same, sort by required
        return b.required === a.required ? 0 : a.required ? -1 : 1;
      });

      setOptionLists(sortedOptionLists);
    } catch (error) {
      console.error("Error fetching option lists:", error);
    }
  };

  useEffect(() => {
    fetchOptionLists();
  }, [axiosInstance, loading, product.product_option_lists, product?.branch]);

  useEffect(() => {
    if (optionLists.length > 0) {
      const initialOptions = optionLists.reduce((acc, optionList) => {
        optionList.options.forEach((option) => {
          acc[option.name] = {
            quantity: 0,
            selected: false,
            surcharge: parseFloat(option.surcharge),
          };
        });
        return acc;
      }, {} as SelectedOptions);
      setSelectedOptions(initialOptions);
    }
  }, [optionLists]);

  const handleOptionChange = (
    optionList: OptionList,
    option: Option,
    increment: boolean
  ) => {
    setSelectedOptions((prevOptions) => {
      const newOptions = { ...prevOptions };
      let newQuantity = newOptions[option.name]?.quantity || 0;

      if (optionList.option_type === "increment_decrement") {
        const totalSelected = optionList.options.reduce(
          (total, opt) => total + (newOptions[opt.name]?.quantity || 0),
          0
        );

        // Check if incrementing would exceed max selections
        if (
          increment &&
          (!optionList.max_selections ||
            totalSelected < optionList.max_selections)
        ) {
          newQuantity += 1;
        } else if (!increment) {
          newQuantity = Math.max(newQuantity - 1, 0);
        }
      } else if (optionList.option_type === "multiple_choice") {
        // Set all options in the list to not selected and then select the current one
        optionList.options.forEach((opt) => {
          newOptions[opt.name] = {
            quantity: 0,
            selected: false,
            surcharge: parseFloat(opt.surcharge),
          };
        });
        newQuantity = 1;
      } else if (optionList.option_type === "checkbox") {
        // Get the total selected count for the current option list
        const totalSelected = optionList.options.reduce(
          (total, opt) => total + (newOptions[opt.name]?.selected ? 1 : 0),
          0
        );
        if (increment) {
          if (
            !optionList.max_selections ||
            totalSelected < optionList.max_selections
          ) {
            newQuantity = 1;
          }
        } else {
          newQuantity = 0;
        }
      }

      // Update the selected option in the cloned state
      newOptions[option.name] = {
        quantity: newQuantity,
        selected: newQuantity > 0,
        surcharge: parseFloat(option.surcharge),
      };

      return newOptions;
    });
  };

  const handleQuantityChange = (increment: boolean) => {
    setQuantity((prevQuantity) => {
      const newQuantity = increment
        ? prevQuantity + 1
        : prevQuantity > 1
        ? prevQuantity - 1
        : 1;
      return newQuantity;
    });
  };

  const calculateTotal = () => {
    const baseTotal = parseFloat(product.unit_price) * quantity;
    const optionsTotal = Object.values(selectedOptions).reduce(
      (total, option) => {
        if (option.selected) {
          return total + (option.surcharge ?? 0) * option.quantity * quantity;
        }
        return total;
      },
      0
    );
    return (baseTotal + optionsTotal).toFixed(2);
  };

  const isValidSelection = () => {
    return optionLists.every((optionList) => {
      if (optionList.required) {
        return optionList.options.some(
          (option) => selectedOptions[option.name]?.selected
        );
      }
      return true;
    });
  };

  const handleAddToCart = () => {
    const productToAdd: CartItem = {
      ...product,
      unit_price: product.unit_price,
      quantity,
      selected_options: selectedOptions,
      uniqueId: "", // This will be generated in the reducer
    };
    dispatch(addItem(productToAdd));
    onClose();
  };

  const total = calculateTotal();
  const productPrice =
    total === "0.00" ? "Custom" : (parseFloat(total) / quantity).toFixed(2);

  return (
    <div className="product-modal-overlay" onClick={onClose}>
      <div className="product-modal" onClick={(e) => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="bg-red-500 text-white p-3 rounded-full flex items-center justify-center"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        <div className="header">
          <div className="product-details">
            <h2 className="product-name">{product.name}</h2>
            <div className="price">
              $
              {product.unit_price === "0.00"
                ? productPrice
                : product.unit_price}
            </div>
            <div className="description">{product.description}</div>
          </div>
          <img
            src={product.image}
            alt={product.name}
            className="product-image-modal"
          />
        </div>
        <div className="content">
          {optionLists.map((optionList) => (
            <div key={optionList.id} className="option-list">
              <h3>
                {optionList.name}{" "}
                {optionList.required && (
                  <span className="required-badge">Required</span>
                )}
              </h3>
              {optionList.options.map((option) => (
                <div key={option.id} className="option">
                  {optionList.option_type === "checkbox" ? (
                    <>
                      <input
                        type="checkbox"
                        checked={
                          selectedOptions[option.name]?.selected || false
                        }
                        onChange={() =>
                          handleOptionChange(
                            optionList,
                            option,
                            !selectedOptions[option.name]?.selected
                          )
                        }
                      />
                      <div className="flex justify-between w-full">
                        <span>{option.name}</span>
                        {parseFloat(option.surcharge) > 0.0 && (
                          <span>${option.surcharge}</span>
                        )}
                      </div>
                    </>
                  ) : optionList.option_type === "multiple_choice" ? (
                    <>
                      <input
                        type="radio"
                        name={optionList.name}
                        checked={
                          selectedOptions[option.name]?.selected || false
                        }
                        onChange={() =>
                          handleOptionChange(optionList, option, true)
                        }
                      />
                      <div className="flex justify-between w-full">
                        <span>{option.name}</span>
                        {parseFloat(option.surcharge) > 0.0 && (
                          <span>${option.surcharge}</span>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="quantity-option">
                        <button
                          onClick={() =>
                            handleOptionChange(optionList, option, false)
                          }
                        >
                          -
                        </button>
                        <span>
                          {selectedOptions[option.name]?.quantity || 0}
                        </span>
                        <button
                          onClick={() =>
                            handleOptionChange(optionList, option, true)
                          }
                        >
                          +
                        </button>
                      </div>
                      <div className="flex justify-between w-full">
                        <span>{option.name}</span>
                        {parseFloat(option.surcharge) > 0.0 && (
                          <span>${option.surcharge}</span>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="footer">
          <div className="quantity">
            <button onClick={() => handleQuantityChange(false)}>-</button>
            <span>{quantity}</span>
            <button onClick={() => handleQuantityChange(true)}>+</button>
          </div>
          <button
            className="add-to-basket"
            onClick={handleAddToCart}
            disabled={!isValidSelection()}
          >
            Add to Cart ${total}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
