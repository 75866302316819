import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardContent } from "@/components/back/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Category, OperatingHour, OptionList } from "@/utils/types";
import OptionListSelectionModal from "@/components/ui/modals/OptionListSelectionModal";
import OperatingHoursInput from "@/components/ui/selectors/OperatingHoursInput";

interface AddCategoryProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

const AddCategory = ({
  onMenuItemClick,
  setSuccessMessage,
}: AddCategoryProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();

  const [category, setCategory] = useState<Category>({
    id: 0,
    name: "",
    description: "",
    status: "",
    parent_category: null,
    option_lists: [],
  });
  const [isOptionListModalOpen, setIsOptionListModalOpen] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteHourId, setDeleteHourId] = useState<number | null>(null);

  useEffect(() => {
    const fetchAllCategories = async () => {
      if (!loading && axiosInstance && user?.selectedBranch) {
        try {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          const url = `${apiUrl}?branch=${user.selectedBranch.id}&page=1&page_size=100`;

          const response = await axiosInstance.get(url);
          const { results } = response.data;

          setCategories(results);
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      }
    };

    fetchAllCategories();
  }, [axiosInstance, loading, user?.selectedBranch]);

  const handleAddCategory = async () => {
    const selectedBranch = user?.selectedBranch?.id;

    // Calculate the new ranking
    const highestRanking = Math.max(
      ...categories.map((cat) => cat.ranking || 0),
      0
    );
    const newRanking = highestRanking + 1;
    const transformedOptionLists = category.option_lists.map((optionList) => ({
      id: optionList.id,
      name: optionList.name,
      branch: optionList.branch,
      options: optionList.options.map((option) => option.id),
      required: optionList.required,
      option_type: optionList.option_type,
      max_selections: optionList.max_selections,
    }));

    const categoryData = {
      ...category,
      branch: selectedBranch,
      ranking: newRanking,
      option_lists: transformedOptionLists,
    };

    try {
      if (!loading && axiosInstance) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        const response = await axiosInstance.post(apiUrl, categoryData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const hoursApiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_HOURS ?? "";
        for (const hour of operatingHours) {
          await axiosInstance.post(hoursApiUrl, {
            day: hour.day,
            open_time: hour.open_time,
            close_time: hour.close_time,
            category: response.data.id,
          });
        }
        const successMessage = t("back.management.menu.category.successadd");
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("category", successMessage);
      }
    } catch (error) {
      console.error("Error adding category:", error);
      setIsSuccess(false);
      setBannerMessage(t("back.management.menu.category.failadd"));
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleOptionListSave = (selectedOptionLists: OptionList[]) => {
    const updatedOptionLists = selectedOptionLists.map((optionList) => {
      const existingOptionList = category.option_lists.find(
        (existingList) => existingList.id === optionList.id
      );

      return existingOptionList
        ? { ...existingOptionList, ...optionList }
        : optionList;
    });

    setCategory((prevCategory) => ({
      ...prevCategory,
      option_lists: updatedOptionLists,
    }));

    setIsOptionListModalOpen(false);
  };

  const handleRemoveOptionList = (id: number) => {
    setCategory((prevCategory) => ({
      ...prevCategory,
      option_lists: prevCategory.option_lists.filter(
        (optionList) => optionList.id !== id
      ),
    }));
  };

  // Placeholder for promotions and categories options
  const statusOptions = [
    <option key="active" value="Active">
      {t("back.management.menu.category.status1")}
    </option>,
    <option key="inactivate" value="Inactive">
      {t("back.management.menu.category.status2")}
    </option>,
    <option key="noProduct" value="No Product">
      {t("back.management.menu.category.status3")}
    </option>,
    <option key="draft" value="Draft">
      {t("back.management.menu.category.status4")}
    </option>,
  ];

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.category.addnewcategory")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-full px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="categoryName"
              >
                {t("back.management.menu.category.categoryname")}
              </label>
              <input
                type="text"
                id="itemName"
                placeholder={t(
                  "back.management.menu.category.entercategoryname"
                )}
                value={category.name}
                onChange={(e) =>
                  setCategory({ ...category, name: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.category.description")}
            </label>
            <textarea
              id="description"
              placeholder={t("back.management.menu.category.enterdescription")}
              value={category.description}
              onChange={(e) =>
                setCategory({ ...category, description: e.target.value })
              }
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            />
          </div>
        </div>

        {/* Option List Section */}
        <Card className="mb-4">
          <CardHeader className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">{t("Linked OptionLists")}</h2>
            <button
              onClick={() => setIsOptionListModalOpen(true)}
              className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
            >
              {t("Add")}
            </button>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {category.option_lists.map((optionList) => (
                <span
                  key={optionList.id}
                  className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                >
                  {optionList.name}
                  <button
                    onClick={() => handleRemoveOptionList(optionList.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </span>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Status Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.status")}
          </h2>
          <select
            value={category.status}
            onChange={(e) =>
              setCategory({ ...category, status: e.target.value })
            }
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.category.selectstatus")}
            </option>
            {statusOptions}
          </select>
        </div>

        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.category.operatinghours")}
          </h2>
          <OperatingHoursInput
            operatingHours={operatingHours}
            setOperatingHours={setOperatingHours}
          />
          {operatingHours.map((hour) => (
            <div key={hour.id} className="flex items-center justify-between">
              <div>
                <p>{`${hour.day}: ${hour.open_time} - ${hour.close_time}`}</p>
              </div>
              <button
                onClick={() => {
                  setShowDeleteConfirmation(true);
                  setDeleteHourId(hour.id);
                }}
                className="text-red-500"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("category")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.category.cancelbutton")}
          </button>
          <button
            onClick={handleAddCategory}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.category.confirmbutton")}
          </button>
        </div>
        <OptionListSelectionModal
          isOpen={isOptionListModalOpen}
          onClose={() => setIsOptionListModalOpen(false)}
          onSave={handleOptionListSave}
          initialSelectedOptionLists={category.option_lists.map(
            (optionList) => optionList.id
          )}
        />
      </div>
    </PageLoader>
  );
};

export default AddCategory;
